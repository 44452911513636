import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { state } from '@angular/animations';
import { MatAccordion, fadeInContent } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UIElementDTO } from '../uiElementDTO';
import { UIDataService } from '../uiData.service';
import { Observable, Subscription } from 'rxjs';
import { FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DriverDetailInformation, conviction, withdrawal, GeneralInfo, ResidenceInfo, LicenseDetail, MedicalDetail } from '../DriverDetailInformation';
import { PopupDialogComponent, ACDDetailDesc, ConvictionValidation, AcdCodehint } from '../addConviction/addConviction.component';
import { AddConvictionModel } from '../addConvitionModel';
import { WebUIElementDTO } from '../addWithdrawal/addWithdrawal.component';
import { ACDDTO } from '../acdDTO';
import { CourtTypeDTO } from '../courtTypeDTO';
import { CommVehicleDTO } from '../commVehicleDTO';
import { StateDTO } from '../stateDTO';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService, LoginModel } from '../../_services/authentication.service';
import { User } from '../../account/user';
import { SessionService } from '../../_services/session.service';

function dateErrorValidator(control: AbstractControl): { [key: string]: boolean } | null {
  let validFormat = new RegExp("^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$");
  let date: string[] = control.value.toString().split("/", 3);
  if (!validFormat.test(control.value.toString())) {
    return { 'dateError': true };
  }
  else {
    switch (Number(date[0])) {
      case 1: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 2: {
        if (Number(date[1]) == 29) {
          if ((Number(date[2]) % 4 == 0 && Number(date[2]) % 100 != 0) || (Number(date[2]) % 400 == 0)) {
            return null;
          }
          else {
            return { 'dateError': true };
          }
        }
        else {
          if (Number(date[1]) >= 1 && Number(date[1]) <= 28) {
            return null;
          }
          else {
            return { 'dateError': true };
          }
        }
      }
      case 3: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 4: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 5: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 6: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 7: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 8: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 9: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 10: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 11: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 30) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
      case 12: {
        if (Number(date[1]) >= 1 && Number(date[1]) <= 31) {
          return null;
        }
        else {
          return { 'dateError': true };
        }
      }
    }
  }
  return null;
}

function reinstateDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
  let validFormat = new RegExp("^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$");
  if (validFormat.test(control.value.toString())) {
    if (new Date(control.value) > new Date()) {
      return { 'reinstateDate': true };
    }
  }
  return null;
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-addDriverInfo-component',
  templateUrl: './addDriverInfo.component.html',
  styleUrls: ['./addDriverInfo.component.css']
})

export class addDriverInfoComponent {
  public ACDDetailMessage = new ACDDetailDesc();
  public ConvictionModel = new AddConvictionModel();
  public DriverWithdrawal = new DriverDetailInformation();
  CourtTypeObservable: Observable<CourtTypeDTO>;
  CommVehicleObservable: Observable<CommVehicleDTO>;
  ACDObservable: Observable<ACDDTO>;
  StateObservable: Observable<StateDTO>;
  CountriesObservable: Observable<Country[]>;
  CountrystatesObservable: Observable<State[]>;
  Countrystates: State[];
  message: string;
  subscription: Subscription;
  acdDetailInfo: string;
  isConviction: boolean;
  action: string;
  public ACDHints: AcdCodehint[];
  userInfo: Observable<User>;
  CMVIndicatorValidator = new ConvictionValidation();
  HazmatValidator = new ConvictionValidation();
  public suffixData: Observable<Suffix[]>;
  public DriverInfo = new DriverDetailInformation();
  matcher = new MyErrorStateMatcher();
  userID: string;
  baseUrl: string;
  UIElementObservable: Observable<UIElementDTO>;
  UIElementWebObservable: Observable<WebUIElementDTO>;
  LoginState: string;
  acdDetails: string;
  acdDetailErrorMessage: string;
  public ACDHint = new AcdCodehint();
  datePattern = "^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{4})*$";
  stateDropDown: boolean = false;
  maxStartDate = new Date();

  constructor(private spinner: NgxSpinnerService, private idleTimeoutSvc: SessionService, public dialog: MatDialog, private router: Router, private route: ActivatedRoute, private uiDataService: UIDataService, private http: HttpClient, @Inject('BASE_URL') _baseUrl: string, private authService: AuthenticationService) {
    this.uiDataService.GetDriverInfo.subscribe(message => { this.DriverInfo = message; });
    if (this.route.snapshot.paramMap.get('action') == 'addConviction') { this.isConviction = true; this.commentsFormControl = new FormControl('Conviction Added', [Validators.required, Validators.max(500)]) }
    this.baseUrl = _baseUrl;
    this.CountriesObservable = this.http.get<Country[]>(this.baseUrl + 'api/Data/GetCountryCodes');
    this.CountrystatesObservable = this.http.get<State[]>(this.baseUrl + 'api/Data/CountryStates');
    this.authService.currentUser.subscribe(g => { this.LoginState = g.state, this.getUIElements(this.isConviction, g.state), this.userIdFormControl = new FormControl({ value: g.userID, disabled: true }, []) });
    this.authService.currentUser.subscribe(g => {
      if (g != null) {
        var Account = new LoginModel();
        Account.Password = "xxxxxxxxx";
        Account.UserID = g.userID;
        this.userInfo = this.http.post<User>(this.baseUrl + 'login/Account/RefreshToken', Account);
        this.userInfo.subscribe(g => {
          localStorage.removeItem('currentUser');
          localStorage.setItem('currentUser', JSON.stringify(g));
          this.idleTimeoutSvc.resetTimer();
        });
      }
    });
    this.suffixData = this.http.get<Suffix[]>(this.baseUrl + 'api/Data/SuffixCodes');
    this.acdCodeFormControl.valueChanges.subscribe(g => {
      this.UIElementObservable.subscribe(data => {
        this.ACDHint = data.acdCodes.find(x => x.acdCode == g);
      });
    });
    this.hazmatFormControl.valueChanges.subscribe(g => { this.HazmatValidation(g); });
    this.commvehicleFormControl.valueChanges.subscribe(g => { this.CMVValidator(g); });
    this.CMVIndicatorValidator.Message = "Please enter a CMV indicator.";
    this.HazmatValidator.Message = "Please enter a Hazmat indicator.";
    this.DOBForm.valueChanges.subscribe(g => {
      this.DOBFormControl.setValue(g.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });
    });
    this.DOBFormControl.valueChanges.subscribe(g => {
      if (this.DOBFormControl.valid) {
        this.DOBForm.patchValue(new Date(g), { emitEvent: false, onlySelf: true });
      }
      else {
        this.DOBForm.patchValue('', { emitEvent: false, onlySelf: true });
      }
    });
    this.WithdrawalEffectiveDateForm.valueChanges.subscribe(g => {
      this.WithdrawalEffectiveDateFormControl.setValue(g.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });
    });
    this.WithdrawalEffectiveDateFormControl.valueChanges.subscribe(g => {
      if (this.WithdrawalEffectiveDateFormControl.valid) {
        this.WithdrawalEffectiveDateForm.patchValue(new Date(g), { emitEvent: false, onlySelf: true });
      }
      else {
        this.WithdrawalEffectiveDateForm.patchValue('', { emitEvent: false, onlySelf: true });
      }
    });
    this.WithdrawalEligibleDateForm.valueChanges.subscribe(g => {
      this.WithdrawalEligibleDateFormControl.setValue(g.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });
      if (this.withdrawalEligibleDateList.value == "INDEF" || this.withdrawalEligibleDateList.value == "PERM") {
        this.WithdrawalEligibleDateFormControl.setValidators([Validators.required, dateErrorValidator]);
        this.withdrawalEligibleDateList.patchValue('', { emitEvent: false, onlySelf: true });
      }
      this.WithdrawalEligibleDateFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
      this.WithdrawalEligibleDateFormControl.markAsTouched();
    });
    this.WithdrawalEligibleDateFormControl.valueChanges.subscribe(g => {
      if (this.WithdrawalEligibleDateFormControl.valid) {
        this.WithdrawalEligibleDateForm.patchValue(new Date(g), { emitEvent: false, onlySelf: true });
      }
      else {
        this.WithdrawalEligibleDateForm.patchValue('', { emitEvent: false, onlySelf: true });
      }
      if (this.withdrawalEligibleDateList.value == "INDEF" || this.withdrawalEligibleDateList.value == "PERM") {
        this.WithdrawalEligibleDateFormControl.setValidators([Validators.required, dateErrorValidator]);
        this.WithdrawalEligibleDateFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
        this.withdrawalEligibleDateList.patchValue('', { emitEvent: false, onlySelf: true });
      }
    });

    this.WithdrawalReinstatedDateForm.valueChanges.subscribe(g => {
      this.WithdrawalReinstatedDateFormControl.setValue(g.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });
      this.WithdrawalReinstatedDateFormControl.markAsTouched();
      this.WithdrawalEligibleDateFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
    });
    this.WithdrawalReinstatedDateFormControl.valueChanges.subscribe(g => {
      if (!this.WithdrawalReinstatedDateFormControl.hasError('dateError')) {
        this.WithdrawalReinstatedDateForm.patchValue(new Date(g), { emitEvent: false, onlySelf: true });
      }
      else {
        this.WithdrawalReinstatedDateForm.patchValue('', { emitEvent: false, onlySelf: true });
      }
      this.WithdrawalEligibleDateFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
    });

    this.citationDateForm.valueChanges.subscribe(g => {
      this.citationDateFormControl.setValue(g.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });
    });
    this.citationDateFormControl.valueChanges.subscribe(g => {
      if (this.citationDateFormControl.valid) {
        this.citationDateForm.patchValue(new Date(g), { emitEvent: false, onlySelf: true });
      }
      else {
        this.citationDateForm.patchValue('', { emitEvent: false, onlySelf: true });
      }
    });
    this.convictionDateForm.valueChanges.subscribe(g => {
      this.convictionDateFormControl.setValue(g.toLocaleDateString("en-US"), { emitEvent: false, onlySelf: true });
    });
    this.convictionDateFormControl.valueChanges.subscribe(g => {
      if (this.convictionDateFormControl.valid) {
        this.convictionDateForm.patchValue(new Date(g), { emitEvent: false, onlySelf: true });
      }
      else {
        this.convictionDateForm.patchValue('', { emitEvent: false, onlySelf: true });
      }
    });

    this.CountryFormControl.valueChanges.subscribe(g => {
      this.StateFormControl.setValue("");
      let str: string;
      this.CountriesObservable.subscribe(y => {
        str = y.find(x => x.jurisdictionDesc == g).type;
        if (str == "MX" || str == "US") {
          this.CountrystatesObservable.subscribe(x => {
            this.Countrystates = x.filter(z => z.jurisdictionGroup == str);
          });
          this.stateDropDown = true;
        }
        else {
          this.stateDropDown = false;
        }
      });

    });
    this.StateListFormControl.valueChanges.subscribe(g => {
      this.StateFormControl.setValue(g);
    });

    this.withdrawalEligibleDateList.valueChanges.subscribe(g => {
      this.WithdrawalEligibleDateForm.patchValue('', { emitEvent: false, onlySelf: true });
      this.WithdrawalEligibleDateFormControl.patchValue('', { emitEvent: false, onlySelf: true });
      if (g == "INDEF" || g == "PERM") {
        this.WithdrawalEligibleDateFormControl.setValidators(null);
        this.WithdrawalEligibleDateFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
      }
      else {
        this.WithdrawalEligibleDateFormControl.setValidators([Validators.required, Validators.pattern(this.datePattern)]);
        this.WithdrawalEligibleDateFormControl.updateValueAndValidity({ emitEvent: false, onlySelf: true });
      }
    });
    window.scrollTo(0, 0);
  }
  public DefaultValue = {
    Action: '6', Basis: '1', DueProc: '2', ACD: 'A04', Extent: '1'
  };
  private SearchAcdCodeDetails(data: string): boolean {
    let result: boolean;
    for (let i in this.ACDHints) {
      if (this.ACDHints[i].acdCode == data) {
        if (this.ACDHints[i].conditionValue == "Required") {
          result = true;
        }
        else {
          result = false;
        }
        break;
      }
    }
    return result;
  };
  private getUIElements(action: boolean, state: string) {
    let Params = new HttpParams();
    Params = Params.append("state", state);
    if (action) {
      this.action = "Add Conviction";
      this.UIElementObservable = this.http.get<UIElementDTO>(this.baseUrl + 'api/Data/UIElements', { params: Params });
    }
    else {
      this.action = "Add Withdrawal";
      this.UIElementWebObservable = this.http.get<WebUIElementDTO>(this.baseUrl + 'api/Data/UIElementsVMForWithdrawals', { params: Params });
    }
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(PopupDialogComponent, {
      width: '480px',
      data: this.ACDHint
    });
    dialogRef.afterClosed().subscribe(result => {
      this.acdDetailInfo = "";
    });
  }
  public MoreInfo() {
    this.openDialog();
  }
  public CMVValidator(cmvValue: string) {

    if (this.commvehicleFormControl.errors != null) {
      this.CMVIndicatorValidator.IsError = true;
      if (cmvValue != "1" && this.hazmatFormControl.value == "1") {
        this.CMVIndicatorValidator.Message = "* If the Hazmat is Yes, CMV must be Yes.";
      }
      if (cmvValue == "9" && (new Date(this.citationDateFormControl.value) >= new Date("2008-01-01T00:00:00"))) {
        this.CMVIndicatorValidator.Message = "* CMV must not be Unknown if Citation Date is on or after January 1, 2008.";
      }
      else {
        this.CMVIndicatorValidator.Message = "* Please enter a CMV indicator.";
      }
    }
    if ((cmvValue != "1" && this.hazmatFormControl.value == "1") || (cmvValue == "9" && new Date(this.citationDateFormControl.value) >= new Date("2008-01-01T00:00:00"))) {
      this.commvehicleFormControl.setErrors(Validators.required);
      this.CMVIndicatorValidator.IsError = true;
    }
    else {
      this.CMVIndicatorValidator.IsError = false;
      this.CMVIndicatorValidator.Message = "";
    }
  }

  public HazmatValidation(hmzValue: string) {
    if (this.hazmatFormControl.errors != null) {
      this.HazmatValidator.IsError = true;
      if ((hmzValue == "9" && new Date(this.citationDateFormControl.value) >= new Date("2008-01-01T00:00:00"))) {
        this.HazmatValidator.Message = "Hazmat must not be Unknown if Citation Date is on or after January 1, 2008.";
      }
      else {
        this.HazmatValidator.Message = "* Please enter a Hazmat indicator.";
      }
    }
    if ((hmzValue == "9" && new Date(this.citationDateFormControl.value) >= new Date("2008-01-01T00:00:00"))) {
      this.commvehicleFormControl.setErrors(Validators.required);
      this.HazmatValidator.Message = "Error";
    }
    else {
      this.HazmatValidator.IsError = false;
      this.HazmatValidator.Message = "";
    }
  }

  public AddDriverInfo() {
    this.authService.currentUser.subscribe(g => {
      if (g != null) {
        var Account = new LoginModel();
        Account.Password = "xxxxxxxxx";
        Account.UserID = g.userID;
        this.userInfo = this.http.post<User>(this.baseUrl + 'login/Account/RefreshToken', Account);
        this.userInfo.subscribe(g => {
          localStorage.removeItem('currentUser');
          localStorage.setItem('currentUser', JSON.stringify(g));
          this.idleTimeoutSvc.resetTimer();
        });
      }
    });
    this.DriverInfo.generalInfo = { driverDob: new Date(this.DOBFormControl.value), driverSex: null, driverEyeColor: null, driverFirstName: this.FirstNameFormControl.value, driverHeight: null, driverLastName: this.LastNameFormControl.value, driverMiddleName: this.MiddleNameFormControl.value, driverSuffix: this.SuffixFormControl.value, driverWeight: null };
    this.DriverInfo.residenceInfo = { driverMailingCity: this.CityFormControl.value, driverStreetAddress1: this.StreetAddress1FormControl.value, driverStreetAddress2: this.StreetAddress2FormControl.value, driverMailingCountry: this.CountryFormControl.value, driverMailingCounty: this.CountyFormControl.value, driverMailingState: this.StateFormControl.value, driverMailingZipcode: this.ZipFormControl.value };

    //#BUG 5595 FIXED check and see if HQ account is updating on PRODUCTION       
    var userState = this.authService.currentUserValue.state;
    var env = this.authService.currentUserValue.environment;
    
    if (userState.toUpperCase() == "HQ" && env.toUpperCase() == "PRODUCTION") {
      this.DriverInfo.errorMessage = "HQ users are unable to create convictions, withdrawals or negates.";
      this.DriverInfo.isError = true;
      window.scrollTo(0, 0);
    }
    else {
      if (this.isConviction) {
        if (this.convictionDateFormControl.invalid || (this.acdCodeFormControl.invalid && this.acdDetailFormControl.invalid) || this.DOBFormControl.invalid || this.offRefFormControl.invalid || this.offLocatorFormControl.invalid || this.courtTypeFormControl.invalid || this.LastNameFormControl.invalid || this.citationDateFormControl.invalid || this.courtTypeFormControl.invalid || this.acdCodeFormControl.invalid || this.commvehicleFormControl.invalid || this.hazmatFormControl.invalid || this.commentsFormControl.invalid) {
          this.convictionDateFormControl.markAsTouched();
          this.citationDateFormControl.markAsTouched();
          this.DOBFormControl.markAsTouched();
          this.courtTypeFormControl.markAsTouched();
          this.LastNameFormControl.markAsTouched();
          this.commvehicleFormControl.markAsTouched();
          this.hazmatFormControl.markAsTouched();
          this.commentsFormControl.markAsTouched();
          this.acdCodeFormControl.markAsTouched();
          this.offLocatorFormControl.markAsTouched();
          this.offRefFormControl.markAsTouched();
          this.acdDetailFormControl.markAsTouched();
          window.scrollTo(0, 0);
        }
        else {
          this.spinner.show();
          this.DriverInfo.convictionData = { driverLicenseNumber: this.DriverInfo.driverLicense, driverState: this.DriverInfo.driverState, convictionAcdCode: this.acdCodeFormControl.value, convictionAcdCodeDetail: this.acdDetailFormControl.value, convictionCategory: null, convictionCitationDate: this.citationDateFormControl.value, convictionCommercialVehicle: this.commvehicleFormControl.value, convictionCourtType: this.courtTypeFormControl.value, convictionDate: this.convictionDateFormControl.value, convictionHazmatVehicle: this.hazmatFormControl.value, convictionDatePosted: this.convictionDatePostedFormControl.value, convictionDateReceived: this.convictionDatePostedFormControl.value, convictionDriverCdlHolder: "2", convictionJurisdictionCode: this.LoginState, convictionOffenseLocator: this.offLocatorFormControl.value, convictionJurisOffenseReference: this.offRefFormControl.value, convictionId: null, convictionUserId: null, convCheck: false, convictionCourtLocator: this.LoginState, convictionNotes: this.commentsFormControl.value };
          this.http.post<DriverDetailInformation>(this.baseUrl + 'api/Data/AddDriverData', this.DriverInfo).subscribe(
            data => {
              this.spinner.hide();
              if (data.isError == true) {
                window.scrollTo(0, 0);
                this.DriverInfo.errorMessage = data.errorMessage;
              }
              else {
                var myurl = `${"/detail"}/${this.DriverInfo.driverState}/${this.DriverInfo.driverLicense}`;
                this.router.navigateByUrl(myurl);
              }
            },
            error => {
              window.scrollTo(0, 0);
              this.spinner.hide();
            }
          );
        }

      }
      else {
        if (this.wacdCodeFormControl.invalid || this.LastNameFormControl.invalid || this.WithdrawalComments.invalid || this.WithdrawalEffectiveDateFormControl.invalid || this.DOBFormControl.invalid || this.WithdrawalEligibleDateFormControl.invalid || this.WithdrawalEffectiveDateFormControl.invalid || this.WithdrawalStateNativeCodeFormControl.invalid || this.WithdrawalLocatorNumberFormControl.invalid) {
          this.WithdrawalEffectiveDateFormControl.markAsTouched();
          this.DOBFormControl.markAsTouched();
          this.LastNameFormControl.markAsTouched();
          this.WithdrawalComments.markAsTouched();
          this.WithdrawalEligibleDateFormControl.markAsTouched();
          this.WithdrawalStateNativeCodeFormControl.markAsTouched();
          this.WithdrawalLocatorNumberFormControl.markAsTouched();
          this.wacdCodeFormControl.markAsTouched();
          window.scrollTo(0, 0);
        }
        else {
          this.spinner.show();
          let EligiblityCode = "";
          if (this.withdrawalEligibleDateList.value != null || this.withdrawalEligibleDateList.value != "") {
            EligiblityCode = this.withdrawalEligibleDateList.value;
          }
          this.DriverInfo.withdrawalData = { driverLicenseNumber: this.DriverInfo.driverLicense, withdrawalEligibilityCode: EligiblityCode, driverState: this.DriverInfo.driverState, withdrawalHistoryComments: this.WithdrawalComments.value, withdrawalDisqLetterSentDate: null, withdrawalDqLetterMailedDate: null, withdrawalReinstateSentDate: null, linkage: null, manualElectronic: null, withdrawalDuration: null, withdrawalDateReceived: null, withdrawalReinstateLetterNeeded: null, withdrawalJurisdictionLocator: this.WithdrawalStateNativeCodeFormControl.value, withdrawalDisqLetterNeeded: null, withdrawalJurisdictionCode: this.LoginState, withdrawalCategory: null, withdrawalUserId: null, withdrawalId: null, withdrawalNoticePeriod: null, withdrawalReasonReference: this.WithdrawalStateNativeCodeFormControl.value, withdrawalAcdCode: this.wacdCodeFormControl.value, withdrawalEffectiveDate: this.WithdrawalEffectiveDateFormControl.value, withdrawalEligibilityDate: this.WithdrawalEligibleDateFormControl.value, withdrawalReinstateDate: this.WithdrawalReinstatedDateFormControl.value, withdrawalActionTypeCode: this.WithdrawalActionFormControl.value, withdrawalBasisCode: this.WithdrawalBasisFormControl.value, withdrawalDueProcStatCode: this.WithdrawalDueProcStatusFormControl.value, withdrawalExtentCode: this.WithdrawalExtentCodeFormControl.value, withdrawalLocatorNumber: this.WithdrawalLocatorNumberFormControl.value, withdrawalStateNativeCode: this.WithdrawalStateNativeCodeFormControl.value, withdrawalDatePosted: this.WithdrawalPostedDateFormControl.value };
          this.http.post<DriverDetailInformation>(this.baseUrl + 'api/Data/AddDriverData', this.DriverInfo).subscribe(
            data => {
              this.spinner.hide();
              if (data.isError == true) {
                window.scrollTo(0, 0);
                this.DriverInfo.errorMessage = data.errorMessage;
              }
              else {
                var myurl = `${"/detail"}/${this.DriverInfo.driverState}/${this.DriverInfo.driverLicense}`;
                this.router.navigateByUrl(myurl);
              }
            },
            error => {
              window.scrollTo(0, 0);
              this.spinner.hide();
            }
          );
        }
      }
    }

    
  }

  offRefFormControl = new FormControl('', [
    Validators.required
  ]);
  offLocatorFormControl = new FormControl('', [
    Validators.required
  ]);
  acdDetailFormControl = new FormControl('', []);

  acdCodeFormControl = new FormControl('', [
    Validators.required
  ]);
  wacdCodeFormControl = new FormControl('', [
    Validators.required
  ]);
  jurisdictionFormControl = new FormControl('', [
    Validators.required
  ]);
  citationDateFormControl = new FormControl('', [
    Validators.required, dateErrorValidator
  ]);
  convictionDateFormControl = new FormControl('', [
    Validators.required, dateErrorValidator
  ]);
  courtTypeFormControl = new FormControl('', [
    Validators.required
  ]);
  commentsFormControl = new FormControl('', [
    Validators.required, Validators.max(500)
  ]);
  hazmatFormControl = new FormControl('', [
    Validators.required
  ]);
  commvehicleFormControl = new FormControl('', [
    Validators.required
  ]);
  convictionDateReceivedFormControl = new FormControl(new Date(), [
    Validators.required
  ]);
  convictionDatePostedFormControl = new FormControl({ value: new Date(), disabled: true }, [
    Validators.required
  ]);
  userIdFormControl = new FormControl('', []);
  convictionOffRefFormControl = new FormControl('', []);

  LinkageFormControl = new FormControl({ value: '', disabled: true }, [Validators.required]);
  WithdrawalStateNativeCodeFormControl = new FormControl('', [
    Validators.required
  ]);
  WithdrawalLocatorNumberFormControl = new FormControl('', [
    Validators.required
  ]);
  WithdrawalExtentCodeFormControl = new FormControl(this.DefaultValue.Extent, [
    Validators.required
  ]);
  WithdrawalDueProcStatusFormControl = new FormControl(this.DefaultValue.DueProc, [
    Validators.required
  ]);
  WithdrawalBasisFormControl = new FormControl(this.DefaultValue.Basis, [
    Validators.required
  ]);
  WithdrawalActionFormControl = new FormControl(this.DefaultValue.Action, [
    Validators.required
  ]);

  WithdrawalReinstatedDateForm = new FormControl('', []);
  WithdrawalReinstatedDateFormControl = new FormControl('', [dateErrorValidator, reinstateDateValidator]);
  withdrawalEligibleDateList = new FormControl('', [
  ]);

  WithdrawalEffectiveDateFormControl = new FormControl('', [
    Validators.required, dateErrorValidator
  ]);
  WithdrawalEligibleDateFormControl = new FormControl('', [
    Validators.required, dateErrorValidator
  ]);

  WithdrawalEffectiveDateForm = new FormControl(new Date(), [
  ]);
  WithdrawalEligibleDateForm = new FormControl('', [
  ]);
  WithdrawalPostedDateFormControl = new FormControl({ value: new Date(), disabled: true }, []);
  WithdrawalComments = new FormControl('Withdrawal Added', [
    Validators.required
  ]);

  citationDateForm = new FormControl('', [
  ]);
  convictionDateForm = new FormControl('', [
  ]);
  ZipFormControl = new FormControl('', []);
  StateFormControl = new FormControl('', []);
  StateListFormControl = new FormControl('', []);
  CountryFormControl = new FormControl('', []);
  CountyFormControl = new FormControl('', []);
  CityFormControl = new FormControl('', []);
  StreetAddress2FormControl = new FormControl('', []);
  StreetAddress1FormControl = new FormControl('', []);
  DOBFormControl = new FormControl('', [Validators.required, dateErrorValidator]);
  DOBForm = new FormControl('', []);

  SuffixFormControl = new FormControl('', []);
  LastNameFormControl = new FormControl('', [Validators.required]);
  MiddleNameFormControl = new FormControl('', []);
  FirstNameFormControl = new FormControl('', []);
}


export class Suffix {
  value: string;
  desc: string;
}
interface Country {
  jurisdictionCode: string;
  jurisdictionDesc: string;
  type: string;
}
export class State {
  jurisdictionCode: string;
  jurisdictionDesc: string;
  jurisdictionGroup: string;
}
