import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import { UserAccountService, MessagesGroups } from './userAccount.service';
import { Observable, Subscription, of, Subject } from 'rxjs';
import { UserAgreementModel } from './userAgreementModel';
import { User } from './user';
import { UIDataService } from '../driverDetail/uiData.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Http } from '@angular/http';
import { HttpClient, HttpParams } from '@angular/common/http';



//interface MessagesGroups {
//  am_message: string;  
//}


interface ProvinceGroups {
  jurisdiction_code: string;
  jurisdiction_desc: string;
  jurisdiction_group: string;
  is_active: number;
  action_date: Date;
  action_type: string;
  action_user: string;
}

@Component({
  selector: 'app-login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})


export class loginComponent implements OnInit {
  public userAgreement = new UserAgreementModel();
  public Account = new LoginData();
  public messages: MessagesGroups[];
  public provinces: ProvinceGroups[];
  public suffixData: Observable<Suffix[]>;
  public Announcements: string;
  public Error: string;
  loginForm: FormGroup;

  loading = false;

  submitted = false;

  returnUrl: string;

  error = '';

  _http: HttpClient;
  _baseUrl: string;
  unsubscribe: Subject<any> = new Subject<any>();
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userAccountService: UserAccountService,
    @Inject('BASE_URL') baseUrl: string,
    private uiService: UIDataService,
    private spinner: NgxSpinnerService,
    http: HttpClient

  ) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.userAccountService.currentMessage.subscribe(message => this.userAgreement = message);
    this.userAccountService.infoMsg.subscribe(message => this.messages = message);
  }

  ngOnInit() {


    // BUG # CR5535 -  Announcement capability functionality
    //this._http.get<MessagesGroups[]>(this._baseUrl + 'api/Info/GetAnnouncements').pipe(takeUntil(this.unsubscribe)).subscribe(result => {
    //  this.messages = result;
    //}, error => console.error(error));
    //this.userAccountService.infoMsg;
    //_userService.user$.subscribe((user: Array<User>) => {
    //  //first call is reached with user = undefined
    //  if (!!user[0]) {
    //    console.log(user[0].name)
    //    this.user = user[0];
    //  }
    //});

    if (!(this.userAgreement.systemAccessNotice && this.userAgreement.privacyActNotification)) {
      var myurl = `${""}`;
      this.authenticationService.logout();
      this.router.navigateByUrl(myurl);
    }
    else {
      this.authenticationService.logout();
    }
  }

  public login() {
    if (this.UserIdFormControl.invalid || this.PasswordFormControl.invalid) {
      this.UserIdFormControl.markAsTouched();
      this.PasswordFormControl.markAsTouched();
    }
    else {
      this.spinner.show();
      this.authenticationService.login(this.UserIdFormControl.value, this.PasswordFormControl.value).pipe(first()).subscribe(
        data => {
          this.spinner.hide();

          if (data.errorCode == null) {
            this.uiService.SendLoginState(data.state);
            this.router.navigate(['/home']);
          }
          else {
            this.Error = this.ErrorMessage(data.errorCode);
          }
        },
        error => {
          this.error = error;
          this.loading = false;
        });
    }
  }
  private ErrorMessage(errorCode: number) {
    let result: number = errorCode;
    switch (result) {
      case 1: {
        return "Invalid credentials. Contact the helpdesk at fmcsasupport@techanax.com or call 1-855-537-7517.";
      }
      case 2: {
        return "Your account has been locked. Contact the helpdesk at fmcsasupport@techanax.com or call 1-855-537-7517.";
      }
      case 3: {
        return "Your account is disabled. Contact the helpdesk at fmcsasupport@techanax.com or call 1-855-537-7517.";
      }
      case 4: {
        return "For account access, please contact the helpdesk at fmcsasupport@techanax.com or call 1-855-537-7517.";
      }
      default: {
        return "Invalid Credentials";
      }
    }
  }

  UserIdFormControl = new FormControl('', [Validators.required]);
  PasswordFormControl = new FormControl('', [Validators.required]);
}


export class Suffix {
  value: string;
  desc: string;
}

interface Messages {
  Message: string;
}
interface Country {
  jurisdictionCode: string;
  jurisdictionDesc: string;
  type: string;
}

export class LoginData {
  UserID: string;
  Password: string;
}
