import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { first, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';
import { UserAccountService, MessagesGroups } from './userAccount.service';
import { Observable, Subscription, of, Subject } from 'rxjs';
import { UserAgreementModel } from './userAgreementModel';
import { User } from './user';
import { UIDataService } from '../driverDetail/uiData.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Http } from '@angular/http';
import { HttpClient, HttpParams } from '@angular/common/http';


@Component({
  selector: 'app-signIn-component',
  templateUrl: './signIn.component.html',
  styleUrls: ['./signIn.component.css']
})

export class signInComponent implements OnInit {
  public Error: string;
  _http: HttpClient;
  _baseUrl: string;
  error = '';
  loading = false;
  email: string;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userAccountService: UserAccountService,
    @Inject('BASE_URL') baseUrl: string,
    private uiService: UIDataService,
    private spinner: NgxSpinnerService,
    http: HttpClient

  ) {
    this._http = http;
    this._baseUrl = baseUrl;   
  }

  ngOnInit() {
    this.spinner.show();

    this.authenticationService.processToken(this.getParam("em")).pipe(first()).subscribe(
      data => {
        this.spinner.hide();

        if (data.errorCode == null) {
          localStorage.removeItem("errorCode");
          this.uiService.SendLoginState(data.state);          
          this.router.navigate(['/home']);
        }
        else {

          //DISPLAY ERROR CODE TO USER
          localStorage.setItem("errorCode", data.errorCode.toString());

          this.router.navigate(['/']);         
        }
      },
      error => {
        this.error = error;
        this.loading = false;
      });
  }

  public login() {

  }

  private ErrorMessage(errorCode: number) {
    let result: number = errorCode;
    switch (result) {
      case 1: {
        return "Invalid credentials. Contact the helpdesk at fmcsasupport@techanax.com or call 1-855-537-7517.";
      }
      case 2: {
        return "Your account has been locked. Contact the helpdesk at fmcsasupport@techanax.com or call 1-855-537-7517.";
      }
      case 3: {
        return "Your account is disabled. Contact the helpdesk at fmcsasupport@techanax.com or call 1-855-537-7517.";
      }
      case 4: {
        return "For account access, please contact the helpdesk at fmcsasupport@techanax.com or call 1-855-537-7517.";
      }
      case 5: {
        return "The user has either cancelled or declined to authorize the client.";
      }
      case 6: {
        return "The authorization request was invalid.";
      }
      default: {
        return "Invalid Credentials";
      }
    }
  }

  private getParam(param) {
    return new URLSearchParams(window.location.search).get(param);
  }
}



