//import { Component, Inject } from '@angular/core';
import { Component, OnInit, Inject } from '@angular/core';
import { UserAgreementModel } from './userAgreementModel';
import { UserAccountService, MessagesGroups } from './userAccount.service';
//import { UserAccountService } from './userAccount.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthenticationService } from '../_services/authentication.service';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Guid } from 'guid-typescript';
import { AppConfiguration } from "read-appsettings-json";
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoginGov } from './logingov'

@Component({
  selector: 'app-userAgreement-component',
  templateUrl: './userAgreement.component.html',
  styleUrls: ['./userAgreement.component.css']
})

export class UserAgreementComponent implements OnInit  {
  public userAgreement = new UserAgreementModel();
  public loginGovParms = new LoginGov();

  public messages: MessagesGroups[];
  _http: HttpClient;
  _baseUrl: string;
  public errNumber: string;
  public errCode: number;
  public Error: string;

  //LOGIN.GOV
  public stateGuid: string;
  public nonceGuid: string;
  public acrValueUrl: string;
  public clientUrl: string;
  public redirectUrl: string;
  public acr_Values: string;
  public client_Id: string;
  public prompt: string;
  public response_type: string;
  public scope: string;
  public state: string;
  public nonce: string;
  public logOutResponseUrl: string;

  error = '';

  constructor(private userAccountService: UserAccountService, private router: Router, private authService: AuthenticationService, @Inject('BASE_URL') baseUrl: string, http: HttpClient) {
    this.authService.logout();
    this.userAgreement.systemAccessNotice = false;
    this.userAgreement.privacyActNotification = false;
    this._http = http;
    this._baseUrl = baseUrl;
    this.userAccountService.currentMessage.subscribe(message => this.userAgreement = message);
    this.userAccountService.infoMsg.subscribe(message => this.messages = message);
  }

  ngOnInit() {
    
    //GET LOGIN.GOV PARAMETERS 
    this.authService.getLoginGovParms().subscribe(
      data => {      
        if (data != null) {
            this.stateGuid = new GuidCreate().id.toString(),
            this.nonceGuid = new GuidCreate().id.toString(),
            this.acrValueUrl = data.acrValueUrl,
            this.clientUrl = data.clientUrl,
            this.redirectUrl = '&redirect_uri=' + data.redirectUrl,
            this.acr_Values = '&acr_values=' + encodeURIComponent(this.acrValueUrl),
            this.client_Id = '&client_id=' + data.clientId,
            this.prompt = '&prompt=' + data.prompt,
            this.response_type = '&response_type=' + data.responseType,
            this.scope = '&scope=' + data.scope,
            this.state = '&state=' + this.stateGuid,
            this.nonce = '&nonce=' + this.nonceGuid
        }           
      });

    this.userAccountService.getInfo();
    this.errNumber = localStorage.getItem("errorCode");

    this.errCode = parseInt(this.errNumber, 0);

    if (this.errCode >= 1) {
      this.Error = this.ErrorMessage(this.errCode);
      localStorage.removeItem("errorCode");
     }
  }

  private ErrorMessage(errorCode: number) {
    let result: number = errorCode;
    switch (result) {
      case 1: {
        return "Username Not on File at the CDLIS Gateway. Please contact the State FCWD User Interface helpdesk at 855-537-7517 or fmcsasupport@techanax.com";
      }
      case 2: {
        return "State FCWD UI User account is disabled. Please contact our helpdesk at 1-855-537-7517 or fmcsasupport@techanax.com if you need further assistance.";
      }
      case 3: {
        return "Your account is disabled. Contact the helpdesk at fmcsasupport@techanax.com or call 1-855-537-7517.";
      }
      case 4: {
        return "For account access, please contact the helpdesk at fmcsasupport@techanax.com or call 1-855-537-7517.";
      }
      case 5: {
        return "The user has either cancelled or declined to authorize the client.";
      }
      case 6: {
        return "The authorization request was invalid. Contact the helpdesk at fmcsasupport@techanax.com or call 1-855-537-7517.";
      }
      default: {
        return "Invalid Credentials";
      }
    }
  }

  public IDisagree() {
    close();
  }
  public IAgreeSAN() {
    this.userAgreement.systemAccessNotice = true;
  }

  public IAgreePAN() {
    this.userAccountService.getInfo();
    this.userAgreement.privacyActNotification = true;
    this.userAgreement.DateTime = new Date().toDateString();
    this.userAccountService.sendMessage(this.userAgreement);

    //BUILD LOGIN.GOV URL
    var loginGovUrl = `${this.clientUrl}/openid_connect/authorize?` + this.acr_Values + this.client_Id + this.prompt + this.redirectUrl + this.response_type + this.scope + this.state + this.nonce;

    //REDIRECT TO LOGIN.GOV
    document.location.href = loginGovUrl;


    //var myurl = `${"/login"}`;
    //this.router.navigateByUrl(myurl);
  }

  private getParam(param) {
    return new URLSearchParams(window.location.search).get(param);
  }
}
export class GuidCreate {
  public id: Guid;
  constructor() {
    this.id = Guid.create(); // ==> b77d409a-10cd-4a47-8e94-b0cd0ab50aa1
  }
}

